

@import '~bootstrap/dist/css/bootstrap';
@import '@fortawesome/fontawesome-free';
@import 'dropzone/dist/basic.css';
@import 'dropzone/dist/dropzone.css';

@import './home.scss';
@import './actiontext.scss'; /* @import './styles.scss'; */
body {
  font-family: Open Sans, Helvetica, sans-serif;
  overflow-x: hidden;
}

.banner {
  background-size: cover;
  float: left;
  width: 100%;
}

.header {
  padding: 15px 0px;
  float: left;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: relative;
  z-index: 9;
}
.affix {
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 9 !important;
}
.logo {
  display: inline-block;
  float: left;
}
.header-right {
  float: right;
  display: inline-block;
  margin-top: 6px;
}
.header-right ul {
  padding: 0px;
  margin: 0px;
}
.header-right li {
  list-style: none;
  display: inline-block;
  position: relative;
}
.header-right li a {
  width: 133px;
  height: 37px;
  line-height: 33px;
  text-align: center;
  border-radius: 50px;
  border: solid 2px #fff;
  font-size: 19px;
  color: #fff;
  margin-right: 20px;
  display: inline-block;
  background: #e88b30;
}
.header-right li a:hover {
  background: #fff !important;
  color: #000;
  text-decoration: none;
}
.header-right li ul {
  display: none;
  position: absolute;
  right: -50px;
  top: 20px;
  width: 270px;
  z-index: 99;
  padding-top: 26px;
}
.header-right li:hover ul {
  display: block;
  margin: 0px;
}
.header-right li ul li {
  list-style: none;
}
.header-right li ul li a {
  color: #fff;
  font-size: 14px;
  font-family: OpenSans-Light, Helvetica, sans-serif;
  padding: 20px;
  border-bottom: solid 1px #484748;
  background: #303030;
  display: block;
}
.header-right li ul li a .fa {
  font-size: 17px;
  width: 35px;
}
.header-right li ul li a:hover {
  color: #fff;
  background: #484748;
  text-decoration: none;
}

.header-right-nav {
  float: right;
  display: inline-block;
  margin-top: 15px;
  margin-left: 15px;
}

.overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-y: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 24px;
  color: #fff;
  font-family: GillSansBold, Helvetica, sans-serif;
  display: block;
  transition: 0.3s;
}
.overlay ul {
  padding: 0px;
  margin: 0px;
}
.overlay ul li {
  list-style: none;
}
.overlay li a {
  display: inline-block;
  width: 30%;
  border-bottom: solid 1px #fff;
  padding: 15px 0px;
}
.overlay li a:hover {
  color: #e88b30;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay {
    overflow-y: auto;
  }
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

.bannerbottombox {
  float: left;
  width: 100%;
  padding-top: 15%;
  padding-bottom: 30px;
}
.bannerbottombox h1 {
  text-align: center;
  color: #000;
  font-size: 32px;
  font-weight: 800;
}
.bannerbottombox-bg {
  padding: 40px 50px;
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 15px;
}

.needjob-box {
  width: 340px;
  border: solid 2px #393939;
  border-radius: 15px;
  text-align: center;
  padding: 10px 35px;
}
.needjob-box h3 {
  display: inline-block;
  padding: 0px 20px;
  background: #fff;
  font-size: 20px;
  color: #000;
  margin: -20px 0 0 0px;
  font-weight: 800;
}
.needjob-box p {
  font-size: 16px;
  margin: 12px 0px;
  line-height: 20px;
}
.needjob-box a {
  display: inline-block;
  border-radius: 50px;
  width: 185px;
  height: 48px;
  line-height: 48px;
  color: #fff;
  background: #e88b30;
  font-size: 22px;
  font-family: GillSansBold, Helvetica, sans-serif;
}
.needjob-box a:hover {
  text-decoration: none;
  color: #fff;
  background: #000;
}

.footer {
  float: left;
  width: 100%;
}

.footertop {
  float: left;
  width: 100%;
  background: #2c2f2f;
  padding: 40px 0px;
  text-align: center;
}

.footerbottom {
  float: left;
  width: 100%;
  padding: 12px 0px;
  background: #000;
  letter-spacing: 0.5px;
}
.copyright {
  float: left;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  font-family: Gill Sans-Light, Helvetica, sans-serif;
}

.footernav {
  float: right;
  display: inline-block;
}
.footernav ul {
  padding: 0px;
  margin: 0px;
}
.footernav li {
  float: left;
  line-height: 17px;
  border-left: solid 1px #fff;
  list-style: none;
  padding: 0px 14px;
  font-family: Gill Sans-Light, Helvetica, sans-serif;
}
.footernav li:first-child {
  border: none;
}
.footernav li a {
  font-size: 17px;
  color: #fff;
}
.footernav li a:hover {
  text-decoration: none;
  color: #e88b30;
}

.logindrop {
  background: #fff;
  border-radius: 15px;
  padding: 15px;
  border: solid 5px #6c6c6d;
  margin-top: 40px;
  position: relative;
}
.logindrop p {
  margin-bottom: 10px;
}
.logindrop::after {
  width: 46px;
  height: 23px;
  content: '';
  position: absolute;
  top: -23px;
  left: 40%;
}
.logindrop label {
  font-size: 13px;
  width: 30%;
}
.logindropinput {
  border-radius: 5px;
  border: solid 1px #ccc;
  outline: none;
  width: 70%;
  padding: 0px 10px;
  font-size: 13px;
  height: 30px;
}
.loginbtn {
  width: 105px;
  height: 30px;
  text-align: center;
  border-radius: 50px;
  border: solid 1px #898989;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  display: inline-block;
  background: #e88b30;
}
.loginbtn:hover {
  background: #cf741a;
}
.forgotpass a {
  width: auto !important;
  height: auto !important;
  color: #e88b30 !important;
  font-family: 'Open Sans', sans-serif !important;
  background: none !important;
  font-size: 12px !important;
  margin: 0px !important;
  line-height: inherit !important;
  border: none !important;
  font-weight: 600;
}
.forgotpass a:hover {
  color: #000 !important;
}
.loginwith {
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  color: #6c6c6d;
}
.loginwithicon {
  text-align: center;
}
.loginwithicon a {
  display: inline-block !important;
  margin: 0px 5px !important;
  width: 26px !important;
  height: 26px !important;
  line-height: 26px !important;
  border-radius: 50% !important;
  color: #fff !important;
  background: #3672b6 !important;
  border: solid 2px #fff !important;
  font-size: 12px !important;
  padding: 5px 0px;
}
.loginwithicon a:hover {
  background: #e88b30 !important;
  color: #e88b30 !important;
  border: solid 2px #e88b30 !important;
}
.loginwithicon .gplus {
  background: #db4646 !important;
}

.footertop-btngrp {
  float: left;
  width: 100%;
  text-align: center;
}
.footertop-btngrp a {
  width: 260px;
  height: 40px;
  line-height: 37px;
  font-size: 14px;
  display: inline-block;
  margin: 0px 20px;
  text-transform: uppercase;
  border: solid 2px #f49c24;
  background: #f49c24;
  color: #fff;
  font-weight: 600;
  border-radius: 50px;
}
.footertop-btngrp a:hover {
  background: #cd7a09 !important;
  border: solid 2px #cd7a09;
  color: #fff;
  text-decoration: none;
}

.footertop-nav {
  padding: 0 0 40px 0px;
  float: left;
  width: 100%;
}
.footertop-nav ul {
  padding: 0px;
  margin: 0px;
}
.footertop-nav li {
  list-style: none;
  display: inline-block;
  padding: 0px 18px;
}
.footertop-nav li a {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
}
.footertop-nav li a:hover {
  text-decoration: none;
  color: #f49c24;
}

.footertop-social-bg {
  float: left;
  width: 100%;
  padding: 22px 0px;
}
.footertop-social {
  border-top: solid 1px #f49c24;
  float: left;
  width: 100%;
}
.footertop-social ul {
  display: inline-block;
  padding: 0px 10px;
  background: #2c2f2f;
  margin: -22px 0 0 0px;
}
.footertop-social li {
  display: inline-block;
  padding: 0px 8px;
}
.footertop-social li a {
  display: block;
  width: 40px;
  height: 40px;
  padding: 12px 0px;
  font-size: 18px;
  text-align: center;
  border-radius: 50%;
  color: #2c2f2f;
  background: #f49c24;
}
.footertop-social li a:hover {
  text-decoration: none;
  color: #f49c24;
  background: #fff;
}

.footertop-rights {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  float: left;
  width: 100%;
}

/***/

.bodycontent {
  float: left;
  width: 100%;
}
.resetpass {
  padding: 135px 0px;
  float: left;
  width: 100%;
}
.resetpass-bg {
  padding: 30px 20px;
  border-radius: 25px;
  background: #fff;
  float: left;
  width: 100%;
  box-shadow: 0px 0px 8px #ececec;
}
.resetpass-bg h1 {
  color: #fe9300;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin: 0 0 25px 0px;
}

.reset-form {
  padding: 0px;
  float: left;
  width: 100%;
}
.reset-form form {
  width: 100%;
}
.reset-form .form-control {
  width: 100%;
  margin: 0px;
  height: 50px;
  outline: none;
  padding: 0px 20px 0 55px;
  background: #fff;
  position: relative;
  border: solid 1px #e2e2e2;
  font-size: 15px;
  border-radius: 0px;
  color: #747373;
}
.reset-form select {
  text-indent: 55px;
  padding-left: 0px !important;
}
.form-group .fa,
.form-group .svg-inline--fa {
  position: absolute;
  color: #e88b30;
  left: 40px;
  top: 17px;
  z-index: 9;
}
.reset-form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #747373;
}
.reset-form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #747373;
}
.reset-form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #747373;
}
.reset-form input:-moz-placeholder {
  /* Firefox 18- */
  color: #747373;
}
.resetbtn {
  border: none;
  padding: 0px;
  height: 50px;
  border-radius: 0px;
  color: #fff;
  font-size: 15px;
  width: 100%;
  font-weight: 800;
  outline: none;
  background: rgb(248, 171, 74); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(248, 171, 74, 1) 0%,
    rgba(246, 151, 60, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(248, 171, 74, 1) 0%,
    rgba(246, 151, 60, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(248, 171, 74, 1) 0%,
    rgba(246, 151, 60, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8ab4a', endColorstr='#f6973c',GradientType=1 ); /* IE6-9 */
}
.resetbtn:hover {
  background: #e88b30;
}

.signup {
  padding: 55px 0px;
}
.socialbtn {
  border: none;
  padding: 0px;
  height: 50px;
  line-height: 50px;
  margin-bottom: 15px;
  border-radius: 0px;
  color: #fff;
  font-size: 15px;
  width: 100%;
  font-weight: 800;
  display: block;
  text-align: center;
}
.socialbtn:hover {
  color: #fff;
  text-decoration: none;
}
.socialbtnfb {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5e8fca+0,4c75be+100 */
  background: rgb(94, 143, 202); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(94, 143, 202, 1) 0%,
    rgba(76, 117, 190, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(94, 143, 202, 1) 0%,
    rgba(76, 117, 190, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(94, 143, 202, 1) 0%,
    rgba(76, 117, 190, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5e8fca', endColorstr='#4c75be',GradientType=1 ); /* IE6-9 */
}
.socialbtngplus {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e0425b+0,da364b+100 */
  background: rgb(224, 66, 91); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(224, 66, 91, 1) 0%,
    rgba(218, 54, 75, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(224, 66, 91, 1) 0%,
    rgba(218, 54, 75, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(224, 66, 91, 1) 0%,
    rgba(218, 54, 75, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0425b', endColorstr='#da364b',GradientType=1 ); /* IE6-9 */
}
.termstext {
  color: #a5a5a5;
  font-size: 11px;
  padding-bottom: 15px;
  padding-top: 15px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  line-height: 22px;
}
.termstext a {
  color: #4e4e4e;
}
.termstext a:hover {
  color: #f6993d;
}

.stepsbg {
  float: left;
  width: 100%;
}

.stepsnav,
.mobile-stepsnav {
  float: left;
  width: 100%;
  padding: 35px 0px;
  text-align: center;
}
.stepsnav ul,
.mobile-stepsnav ul {
  padding: 0px;
  margin: 0px;
}
.stepsnav li,
.mobile-stepsnav li {
  display: inline-block;
  position: relative;
  width: 255px;
}
.stepsnav li::before,
.mobile-stepsnav li::before {
  height: 5px;
  background: #e4e4e4;
  width: 100%;
  position: absolute;
  left: -120px;
  top: 30px;
  content: '';
}
.stepsnav li:first-child::before,
.mobile-stepsnav li:first-child::before {
  display: none;
}
.roundorangebg {
  display: inline-block;
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  border-radius: 50%;
  font-size: 25px;
  color: #fff;
  font-weight: 800;
  background: #e4e4e4;
  position: relative;
  z-index: 99;
}
.stepsnav li h4,
.mobile-stepsnav li h4 {
  color: #333333;
  font-size: 15px;
  font-weight: 600;
  margin: 15px 0 0 0px;
}
.stepsnav li.active .roundorangebg,
.stepsnav li.active::before,
.mobile-stepsnav li.active .roundorangebg,
.mobile-stepsnav li.active::before {
  background: #f29a44;
}

.bgwhite {
  background: #fff;
}

.steps-bottom {
  float: left;
  width: 100%;
  padding-bottom: 80px;
}

.steps-bottom-left {
  background: #fafafa;
  float: left;
  width: 100%;
  border-radius: 20px;
  padding: 50px 0px;
  text-align: center;
}
.topimg {
  float: left;
  width: 100%;
  padding: 35px 0px;
}
.topimg img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.socialmedia-links {
  float: left;
  width: 100%;
  padding: 20px 0px;
  border-bottom: solid 1px #e6e6e6;
  border-top: solid 1px #e6e6e6;
}
.socialmedia-links h5 {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 20px 0px;
}
.socialmedia-links a {
  width: 40px;
  height: 40px;
  padding: 12px 0px;
  margin: 0px 3px;
  display: inline-block;
  color: #fff;
  font-size: 15px;
  border-radius: 50%;
}
.socialmedia-links a:hover {
  background: #f4ab36;
  color: #fff;
  text-decoration: none;
}

.socialmedia-links .fb {
  background: #6aade6;
}
.socialmedia-links .twi {
  background: #70d9f7;
}
.socialmedia-links .insta {
  background: #e9a67a;
}

.uploads {
  float: left;
  width: 100%;
  padding: 15px 45px;
}
.uploads h5 {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
}
.uploads p {
  color: #9fa3a4;
  font-size: 12px;
  font-weight: 600;
  margin: 8px 0 30px 0px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  margin-bottom: 15px;
  display: inline-block;
}
.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.adddoc {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f38b5e+0,f1714c+100 */
  background: rgb(243, 139, 94); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(243, 139, 94, 1) 0%,
    rgba(241, 113, 76, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(243, 139, 94, 1) 0%,
    rgba(241, 113, 76, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(243, 139, 94, 1) 0%,
    rgba(241, 113, 76, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f38b5e', endColorstr='#f1714c',GradientType=1 ); /* IE6-9 */
}

.steps-bottom-right {
  background: #fafafa;
  float: left;
  width: 100%;
  border-radius: 20px;
  padding: 25px;
  text-align: left;
}

.steps-bottom-right .form-inline .form-group {
  margin-bottom: 20px;
}

.main {
  display: block !important;
  position: relative;
  padding-left: 35px;
  line-height: 23px;
  margin-bottom: 5px !important;
  cursor: pointer;
  font-size: 15px;
  color: #333333;
  text-align: left;
}

/* Hide the default checkbox */
.main input[type='checkbox'] {
  visibility: hidden;
}
/* Creating a custom checkbox
		based on demand */
.geekmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #000;
}

/* Specify the background color to be
		shown when checkbox is checked */
.main input:checked ~ .geekmark {
  background-color: #fff;
}
/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Display checkmark when checked */
.main input:checked ~ .geekmark:after {
  display: block;
}
/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and
		showing only two border to make it look
		like a tickmark */
.main .geekmark:after {
  left: 6px;
  bottom: 5px;
  width: 8px;
  height: 12px;
  border: solid #f2972c;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.career-interests {
  text-align: left;
}
.career-interests h4 {
  color: #333333;
  font-size: 15px;
  width: 100%;
}
.career-interests h5 {
  color: #e88b30;
  font-size: 13px;
  font-weight: 600;
  width: 100%;
  margin: 10px 0px;
}
.career-interests p {
  margin-bottom: 5px;
}

.nextbtn {
  width: auto;
  padding: 0px 70px;
  display: inline-block;
}

.step3-content {
  text-align: left;
}
.step3-content h2 {
  border-bottom: solid 1px #e4e4e4;
  margin: 15px 0px;
}
.step3-content h2 span {
  color: #f29a44;
  font-size: 20px;
  display: inline-block;
  background: #fafafa;
  padding-right: 20px;
  font-weight: 800;
  position: relative;
  top: 10px;
}
.step3-contentrow {
  padding: 15px 0px;
  border-bottom: solid 1px #f2f2f2;
}
.step3-contentrow label {
  font-weight: 600;
  color: #333333;
  font-size: 14px;
  margin-bottom: 0px;
}
.editbtn {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  color: #b4b4b4;
  text-align: center;
  padding: 2px 0px;
  margin-left: 10px;
}
.editbtn:hover {
  color: #fff;
  background: #f29a44;
  text-decoration: none;
}
.step3-contentrow p {
  color: #adacac;
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
  line-height: 23px;
}

.step3-btngrp {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.step3-btngrp a {
  display: inline-block;
  margin: 0px 5px;
  padding: 12px 45px;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0px;
  background: rgb(248, 171, 74); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(248, 171, 74, 1) 0%,
    rgba(246, 151, 60, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(248, 171, 74, 1) 0%,
    rgba(246, 151, 60, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(248, 171, 74, 1) 0%,
    rgba(246, 151, 60, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8ab4a', endColorstr='#f6973c',GradientType=1 ); /* IE6-9 */
}
.step3-btngrp a:hover {
  text-decoration: none;
}
.editbtn02 {
  background: rgb(243, 139, 94) !important; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(243, 139, 94, 1) 0%,
    rgba(241, 113, 76, 1) 100%
  ) !important; /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(243, 139, 94, 1) 0%,
    rgba(241, 113, 76, 1) 100%
  ) !important; /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(243, 139, 94, 1) 0%,
    rgba(241, 113, 76, 1) 100%
  ) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f38b5e', endColorstr='#f1714c',GradientType=1 ) !important; /* IE6-9 */
}

.thankyou-msg {
  background: #fafafa;
  padding: 110px 0px;
  text-align: center;
  float: left;
  width: 100%;
  border-radius: 15px;
}
.thankyou-msg .fa {
  color: #e88b30;
  font-size: 80px;
}
.thankyou-msg h1 {
  color: #e88b30;
  font-size: 30px;
  font-weight: 800;
  margin: 40px 0 0 0px;
}

.font14 {
  font-size: 14px;
}

.indent55 {
  text-indent: 55px;
  padding: 0px !important;
}

.heading02 {
  color: #e88b30;
  font-size: 13px;
  font-weight: 600;
  width: 100%;
  margin: 10px 0px;
}

.career-interests-drop label {
  font-weight: normal;
  text-align: left;
  margin-bottom: 20px;
  min-height: 50px;
  font-size: 15px;
}
.career-interests-drop .form-control {
  padding: 0px 15px;
}

.bootstrap-select > .dropdown-toggle {
  width: 100%;
  margin: 0px;
  height: 50px;
  outline: none;
  padding: 0px 20px 0 55px;
  background: #fff;
  position: relative;
  border: solid 1px #e2e2e2;
  font-size: 15px;
  border-radius: 0px;
  color: #747373;
}
.bootstrap-select .dropdown-toggle .filter-option {
  top: 12px !important;
  padding: 0 20px !important;
}

.mobile-stepsnav {
  display: none;
}

.modal-content .heading02 {
  margin: 20px 0 10px 0px;
}

.howitwork {
  float: left;
  width: 100%;
  padding: 70px 0px;
  text-align: center;
}
.howitwork h1 {
  font-family: GillSansBold, Helvetica, sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  margin: 0 0 60px 0px;
}
.howitworkbox {
  position: relative;
}
.howitworkbox span {
  border: solid 8px #f49c24;
  border-radius: 50%;
  text-align: center;
  height: 150px;
  line-height: 150px;
  width: 150px;
  display: inline-block;
  color: #f49c24;
  font-size: 65px;
  background: #2c2f2f;
}
.howitworkbox span .fa {
  line-height: 140px;
}
.howitworkbox h2 {
  font-family: GillSansBold, Helvetica, sans-serif;
  font-size: 25px;
  margin-top: 20px;
  color: #f49c24;
}
.howitworkbox p {
  font-size: 16px;
  margin: 12px 0px;
  line-height: 20px;
}

.howitworkbox-arrow {
  position: absolute;
  right: -14%;
  top: 20%;
}

.check2 {
  display: inline-block !important;
}
.check2 .geekmark {
  border: solid 1px #333;
}

.acc-details {
  background: #fff;
  float: left;
  width: 100%;
  padding-bottom: 130px;
}

.accdetailsbg {
  float: left;
  width: 100%;
  background: #f4f4f4;
  padding: 30px;
  border-radius: 15px;
}

.dashboardnav {
  border-right: solid 2px #d3d3d3;
}
.dashboardnav ul {
  padding: 0px;
  margin: 0px;
}
.dashboardnav li {
  list-style: none;
  margin-bottom: 2px;
  position: relative;
}
.dashboardnav li a {
  display: block;
  padding: 12px 10px;
  color: #504e4e;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}
.dashboardnav li a .fa {
  width: 55px;
  font-size: 17px;
  text-align: center;
}
.dashboardnav li a:hover,
.dashboardnav li a.active {
  color: #fff;
  background: #e88b30;
  text-decoration: none;
}
.dashboardnav li a.active::after {
  content: '';
  width: 10px;
  height: 28px;
  position: absolute;
  top: 8px;
  right: -10px;
}

.accdetail-left {
  padding: 120px 0px;
  text-align: center;
}
.accdetail-left p {
  color: #303030;
  font-size: 15px;
  font-weight: 600;
}
.accdetail-left a {
  display: inline-block;
  padding: 12px 40px;
  color: #fff;
  border-radius: 0px;
  background: #303030;
  font-size: 15px;
  font-weight: 600;
}
.accdetail-left a:hover {
  background: #e88b30;
  color: #fff;
  text-decoration: none;
}
.accdetail-left a span {
  display: block;
  font-size: 20px;
}

.changepass {
  padding: 0px 50px;
}
.changepass .form-control {
  padding: 0px 20px;
  font-size: 13px;
}
.changepass .form-inline .form-group {
  margin-bottom: 20px;
}
.changepass select {
  text-indent: 20px;
  padding-left: 0px !important;
}

.package-table {
  border: solid 1px #e6e5e5;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  text-align: center;
  margin-bottom: 30px;
}
.package-table h3 {
  color: #3b3b3b;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 0px;
  margin: 0px;
  background: rgb(254, 254, 254); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(254, 254, 254, 1) 0%,
    rgba(237, 244, 246, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(254, 254, 254, 1) 0%,
    rgba(237, 244, 246, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(254, 254, 254, 1) 0%,
    rgba(237, 244, 246, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#edf4f6',GradientType=0 ); /* IE6-9 */
}
.package-table h3 span {
  display: block;
  padding-bottom: 10px;
}
.package-table p {
  margin: 0px;
  color: #3b3b3b;
  font-size: 14px;
  font-weight: 600;
  padding: 16px 0px;
  border-top: solid 1px #e6e5e5;
}

.packageradio {
  display: inline-block !important;
  padding: 0px;
}
.packageradio .geekmark {
  border: solid 1px #e6e5e5;
}
.conbtn {
  display: inline-block;
  padding: 12px 60px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  border-radius: 0px;
  background: #e88b30;
}
.conbtn:hover {
  color: #fff;
  text-decoration: none;
  background: #2c2f2f;
}

.jobseekers {
  padding: 30px 0px;
  border-bottom: solid 1px #e2dede;
  float: left;
  width: 100%;
}
.jobseekers-left {
  width: 115px;
  float: left;
}
.jobseekers-left img {
  border-radius: 15px;
  max-width: 100%;
}
.jobseekers-left h5 {
  color: #191919;
  font-size: 15px;
  font-weight: 600;
  margin: 15px 0 0 0px;
  text-align: center;
}
.jobseekers-mid {
  width: 485px;
  float: left;
  margin-left: 25px;
}
.jobseekers-mid h3 {
  color: #353434;
  font-size: 17px;
  font-weight: 600;
  margin: 0 0 12px 0px;
}
.jobseekers-mid span {
  float: left;
  padding-right: 10px;
  color: #4c4c4c;
  font-size: 14px;
  margin-bottom: 10px;
}
.jobseekers-mid span .fa {
  padding-right: 6px;
}
.jobseekersbtngrp {
  float: left;
  width: 100%;
}
.jobseekersbtngrp a {
  display: inline-block;
  padding: 7px 15px;
  border-radius: 0px;
  color: #fff;
  background: #f49c24;
  font-size: 13px;
  font-weight: 600;
  margin-right: 5px;
}
.jobseekersbtngrp a:hover {
  background: #000 !important;
  color: #fff;
  text-decoration: none;
}
.lastupdate {
  padding: 7px 10px !important;
  border-radius: 0px;
  color: #191919;
  background: #f3d396;
  font-size: 13px !important;
  font-weight: 600;
  float: none !important;
}
.readbtn {
  display: inline-block;
  padding: 6px 15px;
  border-radius: 0px;
  color: #fff;
  background: #2e2e2e;
  border: none;
  font-size: 13px;
  font-weight: 600;
  outline: none;
  margin-right: 5px;
}

.jobseekers-right {
  float: right;
  display: inline-block;
}
.jobseekers-right .sociallink {
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  padding-top: 5px;
  background: #f49c24;
  border-radius: 50%;
  color: #fff;
  font-size: 15px;
  margin-right: 1px;
}
.jobseekers-right .sociallink:hover {
  background: #2e2e2e;
  color: #fff;
  text-decoration: none;
}
.deletelink {
  color: #b71616;
  font-size: 27px;
  margin-top: 5px;
  vertical-align: bottom;
  display: inline-block;
  margin-left: 5px;
}
.deletelink:hover {
  color: #2e2e2e;
  text-decoration: none;
}

.menulinktop {
  padding: 20px 0px;
  float: left;
  width: 100%;
}
.menulinktop ul {
  padding: 0px;
  margin: 0px;
}
.menulinktop li {
  float: left;
  list-style: none;
  padding: 0 10px 0 20px;
}
.menulinktop li:first-child {
  background: none;
  padding-left: 0px;
}
.menulinktop li a {
  color: #5b5a5a;
  font-size: 14px;
  font-weight: 600;
}
.menulinktop li a:hover,
.menulinktop li a.active {
  color: #e88b30;
  text-decoration: none;
}

/*210819*/

.innerbannerdiv {
  width: 100%;
  float: left;
}
.innerbannerdiv img {
  width: 100%;
  float: left;
}
.innercontentbaner {
  width: 100%;
  float: left;
  top: 50%;
  height: 100%;
}
.filttxt {
  width: 100%;
  float: left;
  background: #e88b30;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  padding: 18px 0px;
  cursor: pointer;
}
.inputfilter {
  width: 100%;
  float: left;
  background: #fff;
  text-align: left;
  font-size: 14px;
  color: #62615f;
  font-weight: 600;
  padding: 18px 10px;
  border: none;
  outline: none;
}
.sub-btnbg {
  width: 100%;
  border: none;
  outline: none;
  padding: 17px 0px;
  float: left;
  border-radius: 5px;
  cursor: pointer;
}
.inputstylss::placeholder {
  color: #504c4a;
}

.formbox1 {
  width: 13%;
  float: left;
  margin: 0px 8px;
}
.formbox2 {
  width: 33%;
  float: left;
  margin: 0px 8px;
}
.formbox3 {
  width: 5%;
  float: left;
  margin: 0px 8px;
}
.topserch {
  width: 50%;
  float: right;
}
.serchtopdiv {
  width: 100%;
  float: left;
  padding: 10px 0px;
}
.serchiconss {
  font-size: 18px;
  color: #fff;
  float: left;
  margin: 6px 10px 0px 0px;
}
.inputserchbg {
  background: none;
  border: none;
  float: left;
  padding: 5px 10px;
  color: #fff;
  font-size: 14px;
  width: 74%;
  font-weight: 600;
  outline: none;
}
.inputserchbg::placeholder {
  color: #fff;
}

.innercontentinfo {
  width: 100%;
  position: relative;
  padding: 30px 0px;
  float: left;
}
.innercontentinfo h2 {
  width: 100%;
  font-size: 23px;
  color: #3b3b3b;
  padding: 10px 0px;
  float: left;
}

.bloginfo {
  width: 100%;
  margin: 15px 0px;
  border-bottom: solid 1px #e5e8ea;
  padding-bottom: 15px;
  float: left;
}
.blogsimg {
  width: 100%;
  float: left;
}
.blogsimg img {
  width: 100%;
  float: left;
}
.blogsimg h3 {
  width: 100%;
  font-size: 15px;
  color: #000;
  padding: 10px 0px;
  text-align: center;
  font-weight: 600;
  float: left;
}
.padroemvoess {
  padding: 0px;
}
.bloginfos {
  width: 100%;
  padding: 5px 0px 0px 15px;
  float: left;
}
.bloginfos h3 {
  width: 100%;
  font-weight: 600;
  font-size: 17px;
  color: #353434;
  float: left;
  padding-bottom: 5px;
}
.bloginfos h6 {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #818181;
  float: left;
  line-height: 20px;
}
.bloicontxt {
  color: #818181;
  font-size: 15px;
  margin: 0px 10px 0px 0px;
}
.infobtndiv {
  width: 100%;
  padding: 10px 0px;
  float: left;
}

.pronews {
  float: left;
  margin: 0 10px 0 0;
}
.profilbg {
  background: #f5f5f5;
  z-index: 0;
  position: relative;
  float: left;
  width: 100%;
  display: none;
  margin: 40px 0 20px 0px;
  padding: 25px 20px;
  border: solid 2px #ccc;
}

.filterbginfo {
  background: #e98f36;
  width: 75%;
  height: 600px;
  min-height: 600px;
  z-index: 99;
  position: absolute;
  display: none;
  margin: 60px 0px 0px;
  padding: 25px 20px;
  border-radius: 5px;
  right: 80px;
}

.readbginfo {
  border-radius: 0px;
  background: #2e2e2e;
  padding: 7px 25px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  border: none;
  outline: none !important;
}
.readbginfo:hover {
  background: #171717;
}
.latestupdtebg {
  border-radius: 0px;
  background: #f3d396;
  padding: 5px 30px;
  color: #4c4c4c;
  font-size: 13px;
  border: solid 2px #f3d396;
  float: left;
  font-weight: 600;
  position: absolute;
  left: 145px;
}

.profile-sumrmy {
  width: 100%;
  float: left;
}
.profile-sumrmy h3 {
  width: 100%;
  font-size: 17px;
  color: #e88b30;
  padding: 0px 0px 20px;
  margin: 0px;
  float: left;
  font-weight: 700;
}
.profile-sumrmy p {
  width: 100%;
  font-size: 13px;
  color: #353535;
  padding: 3px 0px;
  margin: 0px;
  float: left;
  line-height: 22px;
}
.darkm {
  font-weight: 700;
}

.jobseekers .profilbg {
  width: 100%;
  z-index: 1;
  float: left;
}

.filter-infoss {
  width: 100%;
  float: left;
}
.filter-infoss h3 {
  width: 100%;
  font-size: 22px;
  color: #fff;
  padding: 0px;
  margin: 10px 0px 15px;
  float: left;
  font-weight: 700;
}
.filter-infoss p {
  width: 100%;
  font-size: 14px;
  color: #fff;
  padding: 2px 0px;
  margin: 0px;
  float: left;
  line-height: 22px;
}
.sidess {
  float: left;
}

.filter-main {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  margin-bottom: 5px !important;
  cursor: pointer;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
}

/* Hide the default checkbox */
.filter-main input[type='checkbox'] {
  visibility: hidden;
}
/* Creating a custom checkbox
    based on demand */
.geekmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #fff;
  border-radius: 0px;
  border: solid 2px #333;
}

/* Specify the background color to be
    shown when checkbox is checked */
.filter-main input:checked ~ .geekmark {
  background-color: #fff;
}
/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Display checkmark when checked */
.filter-main input:checked ~ .geekmark:after {
  display: block;
}
/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and
    showing only two border to make it look
    like a tickmark */
.filter-main .geekmark:after {
  left: 5px;
  bottom: 4px;
  width: 8px;
  height: 12px;
  border: solid #e98f36;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.chckpad {
  margin: 0px !important;
}

.filterapplybg-btn {
  background: #2e2e2e;
  padding: 13px 40px;
  color: #fff;
  font-size: 14px;
  border: none;
  outline: none !important;
  font-weight: 600;
  text-transform: uppercase;
}
.filterapplybg-btn:hover {
  background: #171717;
}
.filterclear-btn {
  background: #d6d6d6;
  padding: 13px 40px;
  color: #161616;
  font-size: 14px;
  border: none;
  outline: none !important;
  font-weight: 700;
  text-transform: uppercase;
}
.filterclear-btn:hover {
  background: #fff;
}
.btninfofilterss {
  width: 100%;
  float: left;
  text-align: right;
}

/***22-08***/

.howitwork {
  float: left;
  width: 100%;
  padding: 65px 0px;
  text-align: center;
  background: #2c2f2f;
}
.howitwork h1 {
  font-size: 35px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 60px 0px;
  color: #e88b30;
}
.howitworkbox {
  position: relative;
  padding: 0px 60px;
}
.howitworkbox span {
  border: solid 2px #fff;
  border-radius: 50%;
  text-align: center;
  height: 100px;
  line-height: 100px;
  width: 100px;
  display: inline-block;
  color: #fff;
  font-size: 42px;
  background: #e88b30;
}
.howitworkbox span .fa {
  line-height: 95px;
}
.howitworkbox h3 {
  font-size: 28px;
  margin-top: 20px;
  color: #e88b30;
  line-height: 30px;
  text-align: left;
  font-weight: 600;
}
.howitworkbox p {
  font-size: 14px;
  margin: 12px 0px;
  line-height: 20px;
  text-align: left;
  color: #fff;
}

.howitwork-toprow {
  float: left;
  width: 100%;
  margin-bottom: 40px;
}
.howitwork-toprow-content {
  padding: 20px 30px;
  border-radius: 15px;
  background: #f3f3f3;
  float: left;
  width: 45%;
}
.howitwork-toprow-content span {
  float: left;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  background: #e88b30;
  margin: 15px 25px 10px 0px;
  font-size: 40px;
  padding-top: 26px;
}
.howitwork-toprow-content h3 {
  color: #252525;
  font-size: 20px;
  font-weight: 600;
  margin: 15px 0 10px 0px;
}
.howitwork-toprow-content p {
  color: #252525;
  font-size: 14px;
  margin: 0px;
  line-height: 20px;
}
.toprowmidtext {
  width: 10%;
  text-align: center;
  float: left;
  color: #252525;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding-top: 7%;
}

.howit-signupbox {
  background-size: cover;
  float: left;
  width: 100%;
  padding: 90px 0px;
}
.howit-signup {
  width: 540px;
  margin: 0 auto;
  padding: 40px 55px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
}
.howit-signup h1 {
  color: #fff;
  font-size: 37px;
  line-height: 34px;
  font-weight: 600;
  margin: 0 0 30px 0px;
}
.howit-signup a {
  color: #fff;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 700;
  display: inline-block;
  padding: 15px 75px;
  background: #e88b30;
  border-radius: 5px;
}
.howit-signup a:hover {
  background: #cc6f13;
  color: #fff;
  text-decoration: none;
}

.mobileapp-row {
  float: left;
  width: 100%;
  text-align: center;
  padding-top: 50px;
}
.mobileapp-img img {
  max-width: 100%;
}
.mobileapp-text {
  color: #0c0c0c;
  font-size: 45px;
  font-weight: 700;
  padding-top: 35%;
  line-height: 44px;
}

.addfev {
  border-radius: 0px;
  background: #e88b30;
  padding: 7px 25px;
  float: right;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  border: none;
  outline: none !important;
}
.addfev:hover {
  background: #2e2e2e;
  color: #fff;
  text-decoration: none;
}

/*020919*/

.resume-upload-form {
  width: 100%;
  float: left;
}
.reshume-heading {
  width: 100%;
  color: #333333;
  font-size: 14px;
  font-weight: 600;
  float: left;
  padding: 15px 0px 0px;
}

.resume-upload-form p {
  color: #9fa3a4;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.esume-upload-form .inputfile-6 + label {
  color: #2e2e2e;
}

.resume-upload-form .inputfile-6 + label {
  padding: 0;
  width: 100%;
}

.resume-upload-form .inputfile-6:focus + label,
.resume-upload-form .inputfile-6.has-focus + label,
.resume-upload-form .inputfile-6 + label:hover {
  border-color: #565757;
}

.resume-upload-form .inputfile-6 + label span,
.resume-upload-form .inputfile-6 + label strong {
  width: 80%;
  padding: 10px 15px;
}

.resume-upload-form .inputfile-6 + label span {
  display: inline-block;
  text-overflow: ellipsis;
  vertical-align: top;
  border: solid 1px #d5d5d5;
  margin-bottom: 10px;
  font-size: 14px;
  color: #666;
  padding: 10px 30px;
  font-size: 14px;
  height: 45px;
  outline: none;
}

.resume-upload-form .inputfile-6 + label strong {
  color: #fff;
  background-color: #f8a848;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  height: 50px;
  padding: 14px 0px;
  outline: none;
}

.resume-upload-form .inputfile-6:focus + label strong,
.resume-upload-form .inputfile-6.has-focus + label strong,
.resume-upload-form .inputfile-6 + label:hover strong {
  background-color: #e88b30;
  font-weight: 600;
  cursor: pointer;
}
.resume-upload-form input[type='file'] {
  display: none;
}

.resume-show {
  width: 80%;
  text-align: center;
  display: inline-block;
  outline: none;
  text-overflow: ellipsis;
  vertical-align: top;
  border: solid 1px #d5d5d5;
  margin-bottom: 10px;
  border-radius: 0px;
  font-size: 14px;
  color: #666;
  padding: 10px 30px;
  font-size: 14px;
  height: 45px;
}

.bloginfoimg {
  width: 10%;
  float: left;
}
.bloginfocontent {
  width: 89%;
  float: right;
  position: relative;
}

.uploadlogo {
  float: none;
}
.uploadlogo .inputfile-6 + label strong {
  width: 100%;
  text-align: center;
}

.upload-wid-input {
  width: 70% !important;
  float: right !important;
  height: 33px !important;
  padding: 9px 15px !important;
  text-align: left !important;
  border: none !important;
  background: #f5f5f5;
  font-size: 12px !important;
}

.upload-wid {
  width: 30% !important;
  text-align: center !important;
  padding: 8px 0px !important;
  height: 33px !important;
  background: #e4e4e4 !important;
  color: #1c1c1c !important;
  font-size: 13px !important;
}
.chck-border {
  border: solid 2px #333 !important;
}
.balckcolor {
  color: #000 !important;
  font-weight: 600 !important;
}

.referid {
  padding-left: 30px !important;
  font-size: 13px !important;
  font-weight: bold;
}
.reftxt {
  font-weight: normal !important;
}

.dropdown-menu.show {
  display: block;
  min-height: 100px !important;
  height: 100px !important;
}
.droppadss {
  min-height: 10px !important;
  margin-bottom: 10px !important;
}

@media only screen and (min-width: 1920px) and (max-width: 2100px) {
  .filterbginfo {
    right: 364px;
    width: 60%;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1900px) {
  .filterbginfo {
    right: 203px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .filterbginfo {
    right: 103px;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1270px) {
  .banner,
  .footer {
    position: inherit;
  }
  .bannerbottombox {
    padding: 30px 0px;
  }
  .needjob-box {
    width: 100%;
    margin: 20px 0px;
  }
  .header-right-nav {
    margin-right: 30px;
  }
  .uploads {
    padding: 15px;
  }
  .footertop-social-bg .row {
    margin: 0px;
  }
  .howitworkbox h2 {
    font-size: 23px;
  }
  .package-table h3 {
    font-size: 11px;
  }
  .jobseekers-left {
    width: 105px;
  }

  .innerbannerdiv img {
    height: 235px;
  }
  .filterbginfo {
    width: 85%;
    right: 41px;
    margin-top: 60px;
  }

  /***22-08***/

  .howitworkbox {
    padding: 20px 0px;
  }
  .howitworkbox h3,
  .howitworkbox p {
    text-align: center;
  }
  .jobseekers-right {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 959px) {
  .banner,
  .footer {
    position: inherit;
  }
  .bannerbottombox {
    padding: 30px 0px;
  }
  .needjob-box {
    width: 100%;
    margin: 20px 0px;
  }
  .stepsnav li {
    width: 215px;
  }
  .uploads {
    padding: 15px 0px;
  }
  .footertop-social-bg .row {
    margin: 0px;
  }
  .howitworkbox h2 {
    font-size: 17px;
  }
  .howitworkbox-arrow img {
    width: 20px;
  }
  .dashboardnav li a {
    padding: 12px 5px;
    font-size: 11px;
  }
  .dashboardnav li a .fa {
    width: 25px;
    font-size: 15px;
  }
  .changepass {
    padding: 0px;
  }
  .package-table h3 {
    font-size: 12px;
  }
  .jobseekers-mid {
    width: 100%;
    margin: 20px 0px;
  }
  .jobseekers-right {
    width: 100%;
    text-align: center;
  }
  .jobseekersbtngrp a,
  .readbginfo {
    font-size: 11px;
  }

  .innerbannerdiv img {
    height: 235px;
  }
  .filttxt {
    font-size: 12px;
    padding: 10px 0px;
  }
  .inputfilter {
    font-size: 12px;
    padding: 10px 10px;
  }
  .formbox1 {
    margin: 0px 4px;
  }
  .formbox2 {
    margin: 0px 4px;
  }
  .formbox3 {
    margin: 0px 4px;
  }
  .sub-btnbg {
    padding: 7px 0px;
  }
  .profilbg {
    width: 500px;
    height: 550px;
    min-height: 550px;
  }

  .filterbginfo {
    width: 90%;
    padding: 10px;
    right: 52px;
    margin: 45px 0px 0px;
  }
  .filter-infoss h3 {
    font-size: 14px;
    margin: 10px 0px;
  }
  .filterapplybg-btn {
    padding: 10px 20px;
    font-size: 12px;
  }
  .filterclear-btn {
    padding: 10px 20px;
    font-size: 12px;
  }

  /***22-08***/

  .howitwork-toprow-content {
    width: 100%;
    text-align: center;
  }
  .howitwork-toprow-content span {
    float: none;
    margin: 10px 0px;
    display: inline-block;
  }
  .toprowmidtext {
    width: 100%;
    text-align: center;
    padding: 20px 0px;
  }
  .howitworkbox {
    padding: 20px 0px;
  }
  .howitworkbox h3,
  .howitworkbox p {
    text-align: center;
  }

  .socialmedia-links h5 {
    color: #333333;
  }
  .resume-upload-form .inputfile-6 + label span {
    font-size: 12px;
    height: 40px;
  }
  .resume-upload-form .inputfile-6 + label strong {
    font-size: 13px;
    height: 45px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .needjob-box {
    width: 100%;
    margin: 20px 0px;
  }
  .overlay li a {
    width: 80%;
  }
  .banner,
  .footer {
    position: inherit;
  }
  .header-right {
    width: 100%;
    text-align: center;
    margin-top: 15px;
  }
  .header-right a {
    margin: 0px 8px;
    width: 110px;
  }
  .bannerbottombox {
    padding: 30px 0px;
  }
  .bannerbottombox-bg {
    padding: 20px;
  }
  .footer {
    text-align: center;
  }
  .copyright {
    width: 100%;
    padding-bottom: 15px;
  }
  .footernav {
    width: 100%;
  }
  .footernav li {
    float: none;
    display: inline-block;
  }
  .footertop-btngrp a {
    width: 170px;
  }
  .footertop-nav li {
    padding: 0px 4px;
  }
  .stepsnav li {
    margin: 15px 0px;
  }
  .stepsnav li::before {
    display: none;
  }
  .steps-bottom-right {
    margin-top: 20px;
  }
  .steps-bottom-right {
    text-align: left;
  }
  .footertop-social-bg .row {
    margin: 0px;
  }
  .header-right-nav {
    position: absolute;
    right: 30px;
    top: 0px;
  }
  .logo img {
    width: 140px;
  }
  .affix {
    background: #3f4043;
    z-index: 99 !important;
  }
  .howitworkbox-arrow {
    display: none;
  }
  .dashboardnav {
    border: none;
  }
  .changepass {
    padding: 0px;
    margin-top: 40px;
  }
  .jobseekers-mid {
    width: 100%;
    margin: 20px 0px;
  }
  .jobseekers-right {
    width: 100%;
    text-align: center;
  }

  .innercontentbaner {
    top: 60%;
  }
  .inputserchbg {
    font-size: 10px;
    width: 85%;
  }
  .serchiconss {
    font-size: 12px;
    margin: 6px 0px 0px 0px;
  }
  .innerbannerdiv img {
    height: 235px;
  }
  .filttxt {
    font-size: 12px;
    padding: 10px 0px;
  }
  .inputfilter {
    font-size: 12px;
    padding: 10px 10px;
  }
  .formbox1 {
    margin: 0px 4px;
  }
  .formbox2 {
    margin: 0px 4px;
  }
  .formbox3 {
    margin: 0px 4px;
  }
  .sub-btnbg {
    padding: 7px 0px;
  }

  .filterbginfo {
    width: 90%;
    padding: 10px;
    margin: 40px 0px 0px;
    height: 850px;
    min-height: 850px;
    right: 40px;
  }
  .filter-infoss h3 {
    font-size: 14px;
    margin: 10px 0px;
  }
  .filterapplybg-btn {
    padding: 10px 20px;
    font-size: 12px;
  }
  .filterclear-btn {
    padding: 10px 20px;
    font-size: 12px;
  }
  .nonep {
    display: none;
  }
  .btninfofilterss {
    margin: 10px 0px;
    text-align: left;
  }
  .innercontentinfo {
    margin-top: 100px;
  }

  /***22-08***/

  .howitwork-toprow-content {
    width: 100%;
    text-align: center;
  }
  .howitwork-toprow-content span {
    float: none;
    margin: 10px 0px;
    display: inline-block;
  }
  .toprowmidtext {
    width: 100%;
    text-align: center;
    padding: 20px 0px;
  }
  .howitworkbox {
    padding: 20px 0px;
  }
  .howitworkbox h3,
  .howitworkbox p {
    text-align: center;
  }
  .howit-signup {
    width: 100%;
    padding: 20px;
  }
  .mobileapp-text {
    padding: 30px 0px;
  }
  .howit-signup h1 {
    font-size: 21px;
    margin: 0 0 20px 0px;
  }
  .serchpads {
    top: 60% !important;
  }
  .bloginfocontent {
    width: 100%;
    text-align: center;
  }
  .bloginfoimg {
    width: 100%;
    text-align: center;
  }
  .bloginfoimg img {
    width: 100px;
    display: inline-block;
    float: none;
  }
  .profile-sumrmy {
    text-align: left;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
  .needjob-box {
    width: 100%;
    margin: 20px 0px;
  }
  .overlay li a {
    width: 80%;
  }
  .banner,
  .footer {
    position: inherit;
  }
  .header-right {
    width: 100%;
    text-align: center;
    margin-top: 15px;
  }
  .header-right a {
    margin: 0px 8px;
    width: 110px;
  }
  .bannerbottombox {
    padding: 30px 0px;
  }
  .bannerbottombox-bg {
    padding: 20px;
  }
  .footer {
    text-align: center;
  }
  .copyright {
    width: 100%;
    padding-bottom: 15px;
  }
  .footernav {
    width: 100%;
  }
  .footernav li {
    float: none;
    display: inline-block;
  }
  .footertop-btngrp a {
    width: 170px;
  }
  .footertop-nav li {
    padding: 0px 7px;
  }
  .stepsnav li {
    margin: 15px 0px;
  }
  .stepsnav li::before {
    display: none;
  }
  .steps-bottom-right {
    margin-top: 20px;
  }
  .steps-bottom-right {
    text-align: left;
  }
  .footertop-social-bg .row {
    margin: 0px;
  }
  .logo img {
    width: 110px;
  }
  .header-right-nav {
    position: absolute;
    right: 30px;
    top: 0px;
  }
  .affix {
    background: #3f4043;
    z-index: 99 !important;
  }
  .howitworkbox-arrow {
    display: none;
  }
  .dashboardnav {
    border: none;
  }
  .changepass {
    padding: 0px;
    margin-top: 40px;
  }
  .jobseekers-mid {
    width: 100%;
    margin: 20px 0px;
  }
  .jobseekers-right {
    width: 100%;
    text-align: center;
  }
  .jobseekersbtngrp a {
    float: left;
  }
  .lastupdate {
    float: left !important;
  }
  .bloginfos h6 {
    font-size: 12px;
  }
  .innercontentinfo {
    margin-top: 115px;
  }

  .innercontentbaner {
    top: 48%;
  }
  .topserch {
    width: 100%;
  }
  .inputserchbg {
    font-size: 10px;
    width: 85%;
  }
  .serchiconss {
    font-size: 12px;
    margin: 6px 0px 0px 0px;
  }
  .innerbannerdiv img {
    height: 235px;
  }
  .filttxt {
    font-size: 10px;
    padding: 10px 0px;
  }
  .inputfilter {
    font-size: 10px;
    padding: 10px 10px;
  }
  .formbox1 {
    margin: 0px 5px;
    width: 25%;
  }
  .formbox2 {
    margin: 5px 4px;
    width: 46%;
  }
  .formbox3 {
    margin: 5px 4px;
    width: 19%;
  }
  .padfiltermobile {
    margin: 5px 5px !important;
  }
  .sub-btnbg {
    padding: 6px 0px;
  }

  .filterbginfo {
    width: 91%;
    padding: 10px;
    margin: 40px 0px 0px;
    height: 910px;
    min-height: 910px;
    right: 27px;
  }
  .filter-infoss h3 {
    font-size: 14px;
    margin: 10px 0px;
  }
  .filterapplybg-btn {
    padding: 10px 20px;
    font-size: 12px;
  }
  .filterclear-btn {
    padding: 10px 20px;
    font-size: 12px;
  }
  .nonep {
    display: none;
  }
  .btninfofilterss {
    margin: 10px 0px;
    text-align: left;
  }
  .filter-main {
    line-height: 18px;
    font-size: 12px;
  }

  /***22-08***/

  .howitwork-toprow-content {
    width: 100%;
    text-align: center;
  }
  .howitwork-toprow-content span {
    float: none;
    margin: 10px 0px;
    display: inline-block;
  }
  .toprowmidtext {
    width: 100%;
    text-align: center;
    padding: 20px 0px;
  }
  .howitworkbox {
    padding: 20px 0px;
  }
  .howitworkbox h3,
  .howitworkbox p {
    text-align: center;
  }
  .howit-signup {
    width: 100%;
    padding: 20px;
  }
  .mobileapp-text {
    padding: 30px 0px;
  }
  .howit-signup h1 {
    font-size: 18px;
    margin: 0 0 20px 0px;
  }
  .serchpads {
    top: 60% !important;
  }
  .jobseekersbtngrp a {
    width: 100%;
    text-align: center;
    margin: 5px 0px;
  }
  .jobseekers-mid span {
    width: 100%;
    text-align: center;
  }
  .jobseekers-left {
    width: 100%;
    text-align: center;
  }
  .jobseekers-left img {
    width: 100px;
    display: inline-block;
  }
  .jobseekers-mid h3 {
    text-align: center;
  }
  .pronews {
    width: 100%;
  }
  .readbginfo {
    width: 100%;
    margin-bottom: 5px;
  }
  .bloginfocontent {
    width: 100%;
    text-align: center;
  }
  .bloginfoimg {
    width: 100%;
    text-align: center;
  }
  .bloginfoimg img {
    width: 100px;
    display: inline-block;
    float: none;
  }
  .profile-sumrmy {
    text-align: left;
  }
  .latestupdtebg {
    position: inherit;
    left: 0px;
    width: 100%;
    text-align: center;
  }
}
@media only screen and (max-width: 479px) {
  .needjob-box {
    width: 100%;
    margin: 20px 0px;
  }
  .overlay li a {
    width: 80%;
  }
  .banner,
  .footer {
    position: inherit;
  }
  .header-right {
    width: 100%;
    text-align: center;
    margin-top: 15px;
  }
  .header-right a {
    margin: 0px 8px;
    width: 110px;
  }
  .bannerbottombox {
    padding: 30px 0px;
  }
  .bannerbottombox-bg {
    padding: 20px;
  }
  .footer {
    text-align: center;
  }
  .copyright {
    width: 100%;
    padding-bottom: 15px;
  }
  .footernav {
    width: 100%;
  }
  .footernav li {
    float: none;
    display: inline-block;
  }
  .header-right li a {
    width: 110px;
  }
  .footertop-btngrp a {
    margin: 10px 0px;
  }
  .footertop-nav li {
    padding: 0px 8px;
  }
  .stepsnav li {
    margin: 15px 0px;
  }
  .stepsnav li::before {
    display: none;
  }
  .steps-bottom-right {
    margin-top: 20px;
  }
  .steps-bottom-right {
    text-align: left;
  }
  .resetbtn {
    font-size: 13px;
  }
  .footertop-nav li a {
    font-size: 12px;
  }
  .footertop-social-bg .row {
    margin: 0px;
  }
  .header-right-nav {
    position: absolute;
    right: 30px;
    top: 0px;
  }
  .mobile-stepsnav {
    display: block;
  }
  .stepsnav {
    display: none;
  }
  .logo img {
    width: 110px;
  }
  .affix {
    background: #3f4043;
    z-index: 99 !important;
  }
  .header-right li ul {
    right: 0px;
  }
  .howitworkbox-arrow {
    display: none;
  }
  .dashboardnav {
    border: none;
  }
  .accdetailsbg {
    padding: 20px;
  }
  .dashboardnav li a {
    padding: 12px 5px;
  }
  .changepass {
    padding: 0px;
    margin-top: 40px;
  }
  .jobseekers-mid {
    width: 100%;
    margin: 20px 0px;
  }
  .lastupdate {
    float: left !important;
    margin-top: 5px;
  }
  .jobseekers-right {
    width: 100%;
    text-align: center;
  }

  .innercontentbaner {
    top: 47%;
    padding-left: 15px;
  }
  .inputserchbg {
    font-size: 10px;
    width: 85%;
  }
  .serchiconss {
    font-size: 12px;
    margin: 6px 0px 0px 0px;
  }
  .innerbannerdiv img {
    height: 235px;
  }
  .filttxt {
    font-size: 10px;
    padding: 10px 0px;
  }
  .inputfilter {
    font-size: 10px;
    padding: 10px 10px;
    margin-bottom: 5px;
  }
  .formbox1 {
    margin: 5px 4px;
    width: 28%;
  }
  .formbox2 {
    margin: 0px 4px;
    width: 44%;
  }
  .formbox3 {
    margin: 0px 4px;
    width: 11%;
  }
  .padfiltermobile {
    margin: 0px 5px !important;
  }
  .sub-btnbg {
    padding: 6px 0px;
  }
  .topserch {
    width: 100%;
  }
  .latestupdtebg {
    padding: 7px 14px;
    font-size: 11px;
    left: 0px;
    position: relative;
    width: 100%;
    text-align: center;
    margin: 10px 0px;
  }
  .readbginfo {
    font-size: 12px;
    width: 100%;
  }

  .filterbginfo {
    width: 84%;
    padding: 10px;
    margin: 40px 0px 0px;
    height: 960px;
    min-height: 960px;
    right: 40px;
  }
  .filter-infoss h3 {
    font-size: 14px;
    margin: 10px 0px;
  }
  .filterapplybg-btn {
    padding: 5px 10px;
    font-size: 9px;
  }
  .filterclear-btn {
    padding: 5px 10px;
    font-size: 9px;
  }
  .nonep {
    display: none;
  }
  .btninfofilterss {
    margin: 10px 0px;
    text-align: left;
  }
  .filter-main {
    line-height: 18px;
    font-size: 12px;
  }

  .pronews {
    width: 100%;
  }
  .innercontentinfo {
    margin-top: 120px;
  }

  /***22-08***/

  .howitwork-toprow-content {
    width: 100%;
    text-align: center;
  }
  .howitwork-toprow-content span {
    float: none;
    margin: 10px 0px;
    display: inline-block;
  }
  .toprowmidtext {
    width: 100%;
    text-align: center;
    padding: 20px 0px;
  }
  .howitworkbox {
    padding: 20px 0px;
  }
  .howitworkbox h3,
  .howitworkbox p {
    text-align: center;
  }
  .howit-signup {
    width: 100%;
    padding: 20px;
  }
  .mobileapp-text {
    padding: 30px 0px;
  }
  .howit-signup h1 {
    font-size: 27px;
  }
  .howit-signup a {
    padding: 15px 25px;
  }
  .howit-signup h1 {
    font-size: 18px;
    margin: 0 0 20px 0px;
  }
  .serchpads {
    top: 60% !important;
  }
  .jobseekersbtngrp a {
    width: 100%;
    text-align: center;
    margin: 5px 0px;
  }
  .jobseekers-mid span {
    width: 100%;
    text-align: center;
  }
  .jobseekers-left {
    width: 100%;
    text-align: center;
  }
  .jobseekers-left img {
    width: 100px;
    display: inline-block;
  }
  .bloginfocontent {
    width: 100%;
    text-align: center;
  }
  .bloginfoimg {
    width: 100%;
    text-align: center;
  }
  .bloginfoimg img {
    width: 100px;
    display: inline-block;
    float: none;
  }
  .profile-sumrmy {
    text-align: left;
  }

  .upload-wid {
    width: 40% !important;
    text-align: center !important;
    padding: 8px 0px !important;
    height: 33px !important;
    background: #e4e4e4 !important;
    color: #1c1c1c !important;
    font-size: 12px !important;
  }
  .upload-wid-input {
    width: 60% !important;
  }
}

.bg-darker {
  background-color: #2c2f2f;
}

.avatar_small {
  width: 20px;
}

.avatar_medium {
  width: 40px;
}

.avatar_large {
  width: 68;
}

.avatar_full {
  width: 100%;
}

.main-container {
  // margin-top: 80px;
}
.profile_search {
  margin-top: 40px;
}

.chosen-container-multi .chosen-choices {
  height: 50px !important;
  padding: 5px !important;
}

#q_career_interests_id_eq,
#q_availabilities_id_eq,
#q_job_types_id_eq,
#q_specialties_id_eq,
#q_education_eq,
#q_work_experience_eq,
#career_interests {
  height: 40px;
  width: 100%;
}

/* Pricing Tables */

.pricing {
  text-align: center;
  border: 1px solid #e6e6e6;
  font-size: 13px;
  padding-left: 0;
  border-radius: 4px;
}

.pricing li:first-child,
.pricing li:last-child {
  padding: 20px 13px;
}

.pricing li {
  list-style: none;
  padding: 12px 8px;
}

.pricing li + li {
  border-top: 1px solid #e6e6e6;
}

.pricing h1 {
  font-size: 24px;
}

.pricing h3 {
  margin-bottom: 0;
  font-size: 32px;
}

.pricing span {
  font-size: 12px;
  color: rgba(27, 29, 93, 0.5);
  font-weight: normal;
}

.pricing li:nth-last-child(2) {
  padding: 30px 13px;
}

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }

  .h-#{$space} {
    margin-top: #{$space}px !important;
    margin-bottom: #{$space}px !important;
  }

  .f-#{$space} {
    font-size: #{$space}px !important;
  }
}

.step3-contentrow .step3-description .trix-content {
  color: #adacac;
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
  line-height: 23px;
}

/* Forms */

.form-control {
  padding: 10px 15px;
  border-color: #e6e6e6;
  border-radius: 4px;
  height: auto;
  color: #838d91;
}

.form-control::-webkit-input-placeholder {
  color: rgba(131, 141, 145, 0.6);
}

.form-control:hover {
  border-color: #dbdbdb;
}

.form-control:focus {
  border-color: #75b7ff;
  box-shadow: none;
  color: #384c6d;
  box-shadow: 0 0 0 0.2rem rgba(3, 122, 251, 0.25);
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #037afb;
  background-color: #037afb;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(3, 122, 251, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-label::after {
  top: 3px;
  left: -21px;
  width: 15px;
  height: 15px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  top: 2px;
  left: -21px;
  width: 15px;
  height: 15px;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(3, 122, 251, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(3, 122, 251, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(3, 122, 251, 0.25);
}

.custom-range::-webkit-slider-thumb {
  background-color: #037afb;
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #80bdff;
}

.custom-range::-moz-range-thumb {
  background-color: #037afb;
}

.custom-range::-moz-range-thumb:active {
  background-color: #80bdff;
}

.custom-range::-ms-thumb {
  background-color: #037afb;
}

.custom-range::-ms-thumb:active {
  background-color: #80bdff;
}

.input-group-text {
  border-color: #e6e6e6;
  background: #f2f2f2;
  border-radius: 4px;
}

.custom-file-label {
  padding: 10px 15px;
  border-color: #e6e6e6;
  height: auto;
  border-radius: 4px;
}

.custom-file-label::after {
  padding: 10px 15px;
  height: auto;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.invalid-feedback {
  color: #eb463f;
}

.valid-feedback {
  color: #59bb56;
}

.private-information {
  color: transparent;
  text-shadow: 0 0 5px rgba(255, 255, 255, 1);
}

.blur-background {
  background-color: #2c2f2f;
}

.dropzone {
  border: 2px dashed #0087f7 !important;
  border-radius: 5px;
  background: white;
}

.dropzone .dz-message {
  font-weight: 400;
}

.dropzone .dz-message .note {
  font-size: 0.8em;
  font-weight: 200;
  display: block;
  margin-top: 1.4rem;
}

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }

  .h-#{$space} {
    margin-top: #{$space}px !important;
    margin-bottom: #{$space}px !important;
  }

  .f-#{$space} {
    font-size: #{$space}px !important;
  }
}

.authors {
  .paragraph {
    transition: background-color 0.5s ease;
    &:hover {
      background-color: #f8f9fa;
      cursor: pointer;
    }
  }
  .image {
    text-align: center;
    img {
      max-width: 80%;
    }
  }
  .delete-btn {
    position: absolute;
    right: calc(10% + 38px);
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}

.post-card {
  .header-img {
    width: 100%;
    height: 100px;
    background-size: cover;
    background-position: center;
  }
}

#elements {
  list-style: none;
  padding-left: 0;
}
