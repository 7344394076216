/* app/javascript/stylesheets/custom_toastr.scss */

/* Success Notification */
.toast-success {
  background-color: #28a745 !important; /* Green */
  color: #fff !important;
  border: 1px solid #28a745 !important;
}

/* Error Notification */
.toast-error {
  background-color: #dc3545 !important; /* Red */
  color: #fff !important;
  border: 1px solid #dc3545 !important;
}

/* Info Notification */
.toast-info {
  background-color: #17a2b8 !important; /* Blue */
  color: #fff !important;
  border: 1px solid #17a2b8 !important;
}

/* Warning Notification */
.toast-warning {
  background-color: #ffc107 !important; /* Yellow */
  color: #212529 !important;
  border: 1px solid #ffc107 !important;
}

/* General Toastr Toast Styles */
.toast {
  border-radius: 4px !important;
  padding: 15px !important;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4) !important;
  font-size: 16px !important;
}
